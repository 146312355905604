@use "base/variables" as v;
@use "base/mixins" as m;

.grid-collaborations {
    margin-top: 2rem;

    .section-title {
        h2 {
            color: v.$primaryBlue;
            margin-bottom: 1rem;
        }
    }
}

.grid-1-2-4 {
    .grid-element {
        margin: 4rem;
        height: 9rem;
        img {
            width: auto;
            height: 100%;
        }
    }
}

@include m.telefonoSM {
    .grid-1-2-4 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 4rem;
        .grid-element {
            margin: 1rem;
            margin-bottom: 4rem;
            display: flex;
            justify-content: center;
            img {
                // width: 100%;
                height: 100%;
                width: auto;
            }
        }
    }
}

@include m.telefono {
    .grid-1-2-4 {
        width: 70%;
        margin: inherit auto;
    }
}

@include m.tablet {
    .grid-1-2-4 {
        grid-template-columns: repeat(4,1fr);
        width: 90%;
        margin-top: 5rem;
    }
}