@use "base/variables" as v;
@use "base/mixins" as m;

.grid-section {
    margin: 4rem;
    margin-top: 0;

    .section-title {
        text-align: center;

        h2 {
            font-size: calc(1.25rem + 0.6vw);
            margin: 2rem 2rem 4rem 2rem;
            text-transform: uppercase;
        }
        p {
            font-size: calc(1.25rem + 0.3vw);
        }
    }

    .grid-1-2-3 {
        display: grid;
        gap: 4rem;
        margin-top: 4rem;
        margin-bottom: 2rem;

        .grid-element {
            display: flex;
            justify-content: center;
            flex-direction: column;

            img {
                width: 25%;
                margin: auto;
                padding-bottom: 4rem;
            }

            h3 {
                text-align: center;
            }
        }
    }
}

@include m.telefono {
    .grid-1-2-3 {
        grid-template-columns: 1fr 1fr;
    }
}

@include m.tablet {
    .grid-1-2-3 {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid-section {
        .section-title {
            h2 {
                margin: 4rem 2rem 2rem 2rem;
                margin-top: 0;
            }
        }
    }
}

@include m.desktop {
    .grid-section {
        margin-top: 3rem;
        margin-bottom: 7rem;
        width: 85vw;
        margin-left: auto;
        margin-right: auto;
        .grid-1-2-3 {
            .grid-element {
                img {
                    width: 7rem;
                }
            }
        }
    }
}