// Tipografía

$fuente_principal: var(--bs-body-font-family);

// Colores

$blanco: #ffffff; 
$negro: #212121;
$primaryBlue: #469DD5;
$primaryBlueTransparent: #469ed5a3;
$gris: #30303069;
$primaryBlueDark: rgb(44,62,80);
$grisFondo: #F7F7F7;

// Tamaños de Media Queries

$telefonoSM: 320px;
$telefono: 576px;
$tablet: 768px;
$desktop: 992px;
$desktopXL: 1200px;
$desktopXXL: 1400px;