@use "base/variables" as v;
@use "base/mixins" as m;

.compromise {
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;

    h2 {
        font-size: calc(1.25rem + 1.25vw);
        text-align: center;
        text-transform: uppercase;
    }
    @include m.telefonoSM {
        margin-top: 6rem;
    }
}

.bullet-list {
    margin: 2rem auto;

    .bullet-element {
        margin: 2rem auto;
        text-align: center;

        h3 {
            color: v.$primaryBlue;
            padding-bottom: 1rem;
            padding-right: 10px;
            padding-left: 10px;
            border-bottom: 2px solid v.$primaryBlue;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin-top: 2rem;
            li {
                padding-top: 0.5rem
            };
        }
    }

    @include m.telefono {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .div1 {
            grid-area: 1 / 1 / 2 / 2;
        }

        .div2 {
            grid-area: 1 / 2 / 2 / 3;
        }

        .div3 {
            grid-area: 2 / 1 / 3 / 3;
        }
    }

    @include m.tablet {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        .div1 {
            grid-area: 1 / 1 / 2 / 2;
        }

        .div2 {
            grid-area: 1 / 2 / 2 / 3;
        }

        .div3 {
            grid-area: 1 / 3 / 2 / 4;
        }
    }

    @include m.desktop {
        width: 80%;
    }
}

@include m.desktop {
    .compromise {
        margin: 10rem inherit 7.5rem inherit;
    }
}