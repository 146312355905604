@use "base/variables" as v;
@use "base/mixins" as m;

.counter-section {
    background-image: url('/build/img/general/counter-bg.webp');
    background-position: top;
}

.counter-title {
    position: relative;
    // top: 10px;
    padding: 4rem 3rem;
    h2 {
        font-size: calc(1.25rem + 0.6vw);
        text-align: center;
    }
    p {
        font-size: calc(1.25rem + 0.3vw);
        text-align: center;
    }
    @include m.telefono {
        margin-top: 4rem;
    }
    @include m.tablet {
        margin: 10rem 20%;
        h2 {
            font-size: calc(1.25rem + 1.25vw);
        }
        p {
            padding: 2rem;
            font-size: calc(1.25rem + 0.5vw);
        }
    }
}

.wrapper {
    background-color: v.$primaryBlueTransparent;
    display: flex;
    justify-content: space-around;
    position: relative;
    // top: -10%;
}

.container {
    width: 28vmin;
    height: 28vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2.5rem 0;
    position: relative;
    @include m.telefonoSM {
        position: relative;
        top: 10px;
    }
    @include m.telefono {
        top: -20px;
    }
    @include m.tablet {
        top: -30px;
    }
}

span.num {
    display: grid;
    place-items: center;
    font-weight: 600;
    font-size: 3em;
}

span.text {
    font-size: 1em;
    text-align: center;
    font-weight: 300;
    line-height: 0;
}

@media screen and (max-width: 1200px) {
    .wrapper {
        width: 100vw;
    }

    .container {
        height: 26vmin;
        width: 26vmin;
        font-size: 12px;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        width: 100%;
        flex-wrap: wrap;
        gap: 30px;
    }

    .container {
        width: calc(50% - 40px);
        height: 30vmin;
        font-size: 14px;
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        gap: 15px;
        // top: 0;
        padding-bottom: 5rem;
    }

    .container {
        width: 100%;
        height: 25vmin;
        font-size: 8px;
    }
}

// Custom style

.counter-section {
    color: v.$blanco;
}