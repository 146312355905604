@use "base/variables" as v;
@use "base/mixins" as m;

.carousel-inner {
    height: 30rem;
}

.carousel {
    display: none;
    @include m.tablet {
        display: block;
    }
}

.carousel-item {
    height: 100%;
    img {
        object-fit: cover;
        height: 100%;
    }
}

@include m.desktop {
    .carousel-inner {
        height: 40rem;
    }
}