@use "base/variables" as v;
@use "base/mixins" as m;

.form-body {
  width: 90%;
  margin: auto;
  margin-bottom: 2rem;
  h2 {
    color: v.$primaryBlue;
    font-size: calc(1.25rem + 0.6vw);
    text-align: center;
    text-transform: uppercase;
  }
  @include m.telefono {
    width: 60%;
    margin-bottom: 5rem;
  }
  @include m.desktop {
    width: 50%;
    margin-bottom: 7rem;
  }
}

form {
  .btn-send {
    margin: auto;
    margin-bottom: 2rem;
    background-color: v.$primaryBlue;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: white;
    border: none;
    border-radius: 1rem;
    transition: all 0.5s;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color:darken(v.$primaryBlue,25%);
      transition: all 0.5s;
    }
  }
}

label {
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}

.aviso {
  text-align: left;
}

.invalid-feedback {
  text-align: center;
  font-size: calc(1rem + 0.2vw);
}

.check-form {
  width: 100%;
}

.form-send {
  width: 100%;
}

.form-check {
  margin-left: 3.5rem;
  display: grid;
  grid-template-columns: 1fr;
  padding-left: .5rem;
  padding-bottom: 1rem;
  top: 1rem;
  input {
    padding-right: 5px;
  }
  label {
    position: relative;
    top: -1rem;
    transition: all 0.5s;
    &:hover {
      color: v.$primaryBlue;
      transition: all 0.5s;
    }
  }
  .invalid-feedback {
    position: relative;
    top: -1.5rem;
  }
}

.form-control {
  background-color: lighten(v.$gris,70%);
  border: none;
}