@use "base/variables" as v;
@use "base/mixins" as m;

.section-header {
    background-image: url('/build/img/general/section-header.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7rem;
    display: none;
    h2 {
        color: v.$primaryBlue;
        font-size: calc(1.25rem + 1.5vw);
        text-transform: uppercase;
    }
    div {
        font-size: calc(1.25rem + 0.25vw);
        text-transform: uppercase;
        margin-top: 1rem;
        a {
            transition: all 0.5s;
            &:hover {
                text-decoration: none;
                transition: all 0.5s;
            }
        }
    }
}

@include m.tablet {
    .section-header {
        display: flex;
    }
}

@include m.desktop {
    .section-header {
        padding: 10rem;
    }
}