@use "variables" as v;
@use "base/mixins" as m;

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  line-height: 1.5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(1.15rem + 0.1vw);
}

h2 {
  font-size: calc(1.15rem + 0.2vw);
}

a {
  text-decoration: none;
  color: v.$negro;
}

button {
  background-color: transparent;
  padding: 0.5rem 1rem;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

footer {
  margin-top: auto;
  text-align: center;
  padding: 1rem auto;
}

select {
  border: none;
}

.display-block {
  display: block;
}

.display-none {
  display: none !important;
}