@use "base/variables" as v;
@use "base/mixins" as m;

.video-description {
    display: flex;
    flex-direction: column;
    margin: auto 2rem;

    iframe {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    div {
        h3 {
            text-transform: uppercase;
            color: v.$primaryBlue;
            text-align: center;
            margin: 1rem auto 2rem auto;
            font-size: calc(1.25rem + 1vw);
        }

        p {
            text-align: center;
        }
    }
}

@include m.telefonoSM {
    .video-description {
        margin: 4rem;
        iframe {
            width: 30rem;
            height: 16.9rem;
        }
        margin-bottom: 0;
    }
}

@include m.telefono {
    .video-description {
        iframe {
            width: 40rem;
            height: 22.5rem;
        }
        // flex-direction: row;
    }
}

@include m.desktop {
    .video-description {
        flex-direction: row;
        align-items: center;
        max-width: 96rem;
        margin: 5rem auto;
        iframe {
            width: 88rem;
            height: 24rem;
        }
        div {
            width: 100%;
            margin-left: 5rem;
            margin-right: 2rem;
        }
    }
}