@use "base/variables" as v;
@use "base/mixins" as m;

.flex-column-row {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    &>* {
        padding: 3rem;
        border-top: 1px solid lighten(v.$negro,75%);
        border-right: 1px solid lighten(v.$negro,75%);
        border-left: 1px solid lighten(v.$negro,75%);
        &:last-of-type {
            border-bottom: 1px solid lighten(v.$negro,75%);;
        }
        h2 {
            color: v.$primaryBlue;
            text-transform: uppercase;
            span {
                margin-top: 1rem;
                margin-bottom: 2rem;
                color: v.$negro;
                display: block;
            }
        }
    }
}

@include m.telefono {
    .flex-column-row {
        flex-direction: row;
        &>* {
            padding: 4rem;
            border-right: none;
            border-bottom: 1px solid lighten(v.$negro,75%);
            &:last-of-type {
                border-right: 1px solid lighten(v.$negro,75%);;
            }
        }
    }
}

@include m.tablet {
    .flex-column-row {
        position: relative;
        top: -5rem;
        background-color: v.$blanco;
        margin-bottom: 0;
    }
}

@include m.desktop {
    .flex-column-row {
        width: 85vw;
        margin-left: auto;
        margin-right: auto;
    }
}