@use "base/variables" as v;
@use "base/mixins" as m;

footer {
    background-color: v.$primaryBlueDark;
    color: v.$blanco;
    display: grid;
}

.footer-info {
    text-align: center;
    img {
        width: 20rem;
        margin: 2rem auto;
        margin-bottom: 0;
    }
    &>* {
        margin: 2rem auto;
        p {
            margin: 1rem auto;
        }
    }
    @include m.tablet {
        text-align: left;
    }
}

iframe {
    width: calc(600px/3);
    height: calc(450px/3);
    margin: 2rem auto;
}

@include m.telefonoSM {
    iframe {
        width: calc(600px/2);
        height: calc(450px/2);
        margin: 2rem auto;
    }
}

@include m.telefono {
    footer {
        grid-template-columns: 1fr 2fr;
    }
    .footer-info {
        margin-left: 10%;
        div {
            display: flex;
            justify-content: left;
            i {
                margin-right: 1rem;
                margin-left: 1rem;
            }
            div {
                display: flex;
                flex-direction: column;
                p {
                    margin-left: 0;
                    margin-top: 0rem;
                }
            }
        }
    }
    iframe {
        width: calc(600px/2);
        height: calc(450px/2);
        margin: 2rem auto;
        margin-right: 10%;
    }
}

@include m.tablet {
    .footer-info {
        margin-left: 20%;
    }
    iframe {
        margin-right: 20%;
        width: calc(600px/1.5);
        height: calc(450px/1.5);
    }
}

@include m.desktop {
    footer {
        padding: 5rem 10rem;
    }
}