@use "base/variables" as v;
@use "base/mixins" as m;

.service-description {
    width: 100%;
    margin: auto;
    h2 {
        color: v.$primaryBlue;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: calc(1.25rem + 1vw);
        text-transform: uppercase;
    }
    p {
        font-size: calc(1.25rem + 0.25vw);
    }
    button {
        font-size: calc(1.15rem + 0.25vw);
    }
    ul {
        font-size: calc(1rem + 0.25vw);
    }
    h3 {
        font-size: calc(1rem + 1vw);
        color: v.$primaryBlue;
        text-align: center;
        margin: 2rem auto;
    }
    .accordion {
        h2 {
            margin: 0;
        }
        p {
            font-size: calc(1.1rem + 0.25vw);
        }
    }
}

.service-text {
    background-color: v.$grisFondo;
    padding: 2rem;
}

.accordion-button:not(.collapsed) {
    color: v.$primaryBlueDark;
}

.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.normal-text {
    p {
        // text-transform: lowercase;
        margin-bottom: 0;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: center;
        font-size: calc(1rem + 0.25vw);
    }
}

@include m.telefonoSM {
    .normal-text {
        p {
            margin-left: 10%;
            margin-right: 10%;
            margin-top: 3rem;
        }
    }
}

@include m.tablet {
    .normal-text {
        margin: 3rem auto;
    }
    .service-text {
        display: flex;
        h3 {
            margin-top: 0;
        }
        ul {
            width: 120rem;
            padding-left: 5rem;
            padding-top: 2rem;
        }
    }
    .service-text-description {
        display: flex;
        flex-direction: column;
        align-items: end;
        padding-bottom: 2rem;
        padding-right: 2rem;
    }
    .last-service {
        .service-text-description {
            padding-top: 2rem;
        }
        margin-bottom: 7rem;
    }
}


@include m.desktop {
    .normal-text {
        margin-left: 15%;
        margin-right: 15%;
    }
    .service-text {
        ul {
            width: 100rem;
            padding-left: 15%;
        }
    }
    .service-text-description {
        padding-right: 15%;
    }
}