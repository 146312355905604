@use "base/variables" as v;
@use "base/mixins" as m;

.description-text {
    margin: 0 3rem 4rem 3rem;

    h2 {
        font-size: calc(1.25rem + 0.6vw);
        text-align: center;
        margin-bottom: 2rem;
        text-transform: uppercase;
        color: lighten(v.$negro, 25%);
    }

    p {
        text-align: center;
        font-size: calc(1.25rem + 0.2vw);

        span {
            font-weight: bold;
            color: lighten(v.$negro, 25%);
        }
    }

    @include m.telefonoSM {
        margin-left: 5rem;
        margin-right: 5rem;
    }

    @include m.tablet {
        margin-left: 10%;
        margin-right: 10%;
        p {
            margin: 4rem auto;
        }
    }
}