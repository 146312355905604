@use "base/variables" as v;
@use "base/mixins" as m;

nav {
    font-weight: bold;
    text-transform: uppercase;
    border-top: 1px solid lighten(v.$negro, 75%);
    border-bottom: 1px solid lighten(v.$negro, 75%);
    padding: 0 !important;

    div {
        margin: 0 8rem;
    }
}

.navbar-nav {
    text-align: center;
}

.language {
    text-align: center;
    margin: 2rem;

}

select {
    &:focus {
        outline: none;
    }

    option {
        height: 2rem;
    }
}

@include m.desktop {
    .navbar-brand {
        display: none;
        text-align: left;
    }

    .navbar-nav {
        &>* {
            margin: auto 2rem;
        }
    }

    nav {
        font-size: 1.25rem;

        .language {
            border-left: 1px solid lighten(v.$negro, 75%);
            border-right: 1px solid lighten(v.$negro, 75%);
            height: 5rem;
            display: flex;
            align-items: center;
            margin: 0;
            text-align: left;
            margin-right: 0;

            select {
                margin: 1rem;
                height: 5rem;
            }
        }
    }
}