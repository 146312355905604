@use "base/variables" as v;
@use "base/mixins" as m;

.header-info {
    display: none;
    margin: 1rem 10rem;
    font-size: 1.1rem;
}

.header-logo {
    height: 5rem;

    img {
        height: 100%;
    }
}

.navbar {
    display: flex;
    flex-direction: column;
    .navbar-brand {
        margin: 1rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-content: center;
        img {
            width: 55vw;
            max-width: 15rem;
            align-items: center;
        }
        @include m.desktop {
            display: none;
        }
    }
    .navbar-toggler {
        margin-bottom: 2rem;
        margin-left: 4rem;
        margin-right: 4rem;
        padding: 0.75rem 0.75rem;
    }
    @include m.telefonoSM {
        flex-direction: row;
        .navbar-toggler {
            margin-bottom: unset;
            padding: 0.75rem 1rem;
        }
    }
}

/* Style all font awesome icons */

.fa {
    color: v.$blanco;
    background-color: v.$primaryBlue;
    padding: calc(20px*0.3);
    font-size: calc(30px*0.3);
    width: calc(70px*0.3);
    border-radius: 50%;
    text-decoration: none;
    margin: 5px 2px;
    text-align: center;
    display: inline-block;
    text-rendering: auto;
}

.fa-facebook {
    &:before {
        position: relative;
        top: 1px;
        right: 1px;
    }
}

.fa-instagram {
    &:before {
        position: relative;
        top: 1px;
        right: 0px;
    }
}

/* Add a hover effect if you want */
.fa:hover {
    background-color: lighten(v.$primaryBlue,10%);
    text-decoration: none;
    transition: all 0.1s linear;
    color: v.$blanco;
}

@include m.desktop {
    .header-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-links {
        display: flex;
        justify-content: center;

        &>* {
            padding-right: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                margin-right: 2rem;
                color: v.$primaryBlue;
            }
        }
    }

    .header-info-text {
        display: flex;
        flex-direction: column;

        p {
            margin: 0;

            &:last-of-type {
                color: lighten(v.$negro, 50%);
            }
        }
    }
}